export class ApiError extends Error {
  constructor(
    message: string,
    public statusCode?: number,
    public handled: boolean = false,
  ) {
    super(message);
  }
}

export class AutoSyncIssue {
  constructor(
    public location: string,
    public message: string,
  ) {}
}

export class AutoSyncError extends Error {
  constructor(
    public issues: AutoSyncIssue[],
    public upgradeRequired: boolean,
  ) {
    super(`${issues.length} issues during AutoSync`);
  }
}
