import { useQuasar, type QVueGlobals } from "quasar";
import { ApiError } from "~/errors";
import { useI18n } from "vue-i18n";
import RetryDialog from "~/RetryDialog.vue";

export function useRetryDialog(
  q?: QVueGlobals,
  i18n?: { t: (k: string) => string },
) {
  const quasar = q || useQuasar();
  const { t } = i18n || useI18n();

  async function showRetryDialog(failureInfo?: string | undefined) {
    return new Promise((resolve) => {
      quasar
        .dialog({
          component: RetryDialog,
          componentProps: {
            failureInfo: failureInfo || t("retry-dialog.default-title"),
          },
        })
        .onOk(() => resolve(undefined));
    });
  }

  async function withRetryDialog<T>(
    promiseFactory: () => Promise<T>,
    failureInfo: string | undefined = undefined,
  ): Promise<T | undefined> {
    for (;;) {
      try {
        return await promiseFactory();
      } catch (e) {
        if (e instanceof ApiError) {
          if (e.handled) return undefined;
          await showRetryDialog(failureInfo);
        } else throw e;
      }
    }
  }

  return { withRetryDialog, showRetryDialog };
}
