<template>
  <AdminLayout />
</template>

<script setup lang="ts">
import { inject, onMounted } from "vue";
import { ApiAuthentication } from "~/api";
import AdminLayout from "~/AdminLayout.vue";
import { Routing } from "~/routing";

const routing = inject(Routing.InjectionKey) as Routing;
const auth = inject(ApiAuthentication.InjectionKey) as ApiAuthentication;

onMounted(async () => {
  const projects = await auth.projects();
  const lastProject = routing.lastProject;
  const fallbackProject = projects[0];
  if (lastProject && projects.includes(lastProject))
    await routing.project(lastProject);
  else if (fallbackProject) await routing.project(fallbackProject);
  else await routing.createProject();
});
</script>
