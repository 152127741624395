<script setup lang="ts">
import { ref, onMounted, onUpdated } from "vue";
import QRCode from "easyqrcodejs";

const props = withDefaults(
  defineProps<{
    value: string;
    size?: number;
    border?: number;
    downloadButton?: string | undefined;
  }>(),
  {
    size: 256,
    border: 0,
    downloadButton: undefined,
  },
);

const el = ref();
const qrcode = ref();

function download(name: string) {
  qrcode.value?.download(name);
}

defineExpose({ download });

onMounted(() => {
  qrcode.value = new QRCode(el.value, {
    text: props.value,
    correctLevel: QRCode.CorrectLevel.M,
    width: props.size,
    height: props.size,
    quietZone: props.border,
  });
});
onUpdated(() => qrcode.value?.makeCode(props.value));
</script>

<template>
  <div ref="el" />
</template>
