import {
  type Ref,
  ref,
  readonly,
  type DeepReadonly,
  type InjectionKey,
} from "vue";
import type {
  ProjectApi,
  User,
  ProjectSettings,
  ProjectUpdate,
  ProjectAdmins,
} from "~/api";

class LazyUpdateableRef<T> {
  private _ref: Ref<T | undefined> = ref(undefined);
  private requestedUpdates: undefined | Promise<void> = undefined;

  constructor(private readonly fetch: () => Promise<T | undefined>) {}

  get ref(): Ref<DeepReadonly<T> | undefined> {
    if (this.requestedUpdates === undefined)
      this.requestedUpdates = this._updateRefValue();
    return readonly(this._ref);
  }

  public async update() {
    if (this.requestedUpdates !== undefined)
      await this.requestedUpdates.finally(() => this._updateRefValue());
  }

  private async _updateRefValue() {
    const value = await this.fetch();
    if (value !== undefined) this._ref.value = value;
  }
}

export class ReactiveProjectState {
  static InjectionKey = Symbol() as InjectionKey<ReactiveProjectState>;

  readonly delete: () => Promise<void>;
  readonly autosyncConfig: (configType: "task") => Promise<File>;
  readonly autosync: (file: File) => Promise<void>;
  readonly addUser: (
    lastName: string,
    firstName: string,
    email: string,
    credentialId: string,
  ) => Promise<User>;
  readonly updateUser: (
    userUuid: string,
    lastName: string,
    firstName: string,
    email: string,
    credentialId: string,
  ) => Promise<User>;
  readonly deleteUser: (uuid: string) => Promise<User | null>;
  readonly sendInvitation: (uuid: string) => Promise<void>;
  readonly addAdmin: (email: string) => Promise<void>;
  readonly removeAdmin: (email: string) => Promise<void>;
  readonly updateSettings: (settings: Partial<ProjectUpdate>) => Promise<void>;

  private readonly _users: LazyUpdateableRef<User[] | undefined>;
  private readonly _settings: LazyUpdateableRef<ProjectSettings | undefined>;
  private readonly _admins: LazyUpdateableRef<ProjectAdmins | undefined>;
  private _name: string;

  constructor(
    projectApi: ProjectApi,
    backgroundApiCallWrapper: <T>(
      fn: () => Promise<T>,
    ) => Promise<T | undefined> = (fn) => fn(),
  ) {
    this._name = projectApi.project;
    this._users = new LazyUpdateableRef(() =>
      backgroundApiCallWrapper(() => projectApi.users()),
    );
    this._settings = new LazyUpdateableRef(() =>
      backgroundApiCallWrapper(() => projectApi.settings()),
    );
    this._admins = new LazyUpdateableRef(() =>
      backgroundApiCallWrapper(() => projectApi.admins()),
    );

    projectApi.onupdate = async () => {
      await backgroundApiCallWrapper(() =>
        Promise.all([
          this._users.update(),
          this._settings.update(),
          this._admins.update(),
        ]),
      );
    };

    this.delete = projectApi.delete.bind(projectApi);
    this.addUser = projectApi.addUser.bind(projectApi);
    this.updateUser = projectApi.updateUser.bind(projectApi);
    this.deleteUser = projectApi.deleteUser.bind(projectApi);
    this.sendInvitation = projectApi.sendInvitation.bind(projectApi);
    this.autosyncConfig = projectApi.autosyncConfig.bind(projectApi);
    this.autosync = projectApi.autosync.bind(projectApi);
    this.addAdmin = projectApi.addAdmin.bind(projectApi);
    this.removeAdmin = projectApi.removeAdmin.bind(projectApi);
    this.updateSettings = projectApi.updateSettings.bind(projectApi);
  }

  get users() {
    return this._users.ref;
  }

  get settings() {
    return this._settings.ref;
  }

  get admins() {
    return this._admins.ref;
  }

  get name() {
    return this._name;
  }
}
