<template>
  <q-dialog
    :model-value="true"
    ref="dialogRef"
    @hide="onDialogHide"
    class="delete-project-dialog"
  >
    <q-card style="max-width: 80vw">
      <q-card-section>
        <div class="text-h2 q-px-md">
          {{
            $t("project-deletion-dialog.title", {
              project: props.projectName,
            })
          }}
        </div>

        <ul>
          <li
            v-for="(message, ndx) in $tm('project-deletion-dialog.messages')"
            :key="ndx"
          >
            {{ message }}
          </li>
        </ul>
        <div class="q-px-md">
          {{ $t("project-deletion-dialog.hint") }}
        </div>
        <div class="q-pa-md">
          {{ $t("project-deletion-dialog.confirmation-message") }}
        </div>
        <div class="q-px-md" style="display: flex; flex-direction: row">
          <q-input
            dense
            outlined
            autofocus
            v-model="enteredProjectName"
            :placeholder="props.projectName"
            ref="inputRef"
            class="project-name-confirmation"
            style="flex-grow: 1"
            :rules="[
              (val) => (val && val.length > 0) || $t('input.required'),
              (val) =>
                val == props.projectName ||
                $t('project-deletion-dialog.error.incorrect-project-name'),
            ]"
            lazy-rules
          />
        </div>
      </q-card-section>
      <q-card-actions align="right" class="q-px-lg">
        <q-btn
          :label="$t('project-deletion-dialog.cancel-button')"
          color="primary"
          flat
          @click="onDialogCancel()"
          class="cancel-button"
        />
        <q-btn
          :label="$t('project-deletion-dialog.ok-button')"
          color="negative"
          @click="inputRef?.validate() ? onDialogOK() : undefined"
          class="ok-button"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { QInput, useDialogPluginComponent } from "quasar";

defineEmits({ ...useDialogPluginComponent.emitsObject });
const props = defineProps<{
  projectName: string;
}>();

const enteredProjectName = ref();
const inputRef = ref();

const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } =
  useDialogPluginComponent();
</script>
