<template>
  <q-dialog
    :model-value="true"
    ref="dialogRef"
    @hide="onDialogHide"
    class="upgrade-dialog"
  >
    <q-card class="q-dialog-plugin">
      <q-card-section>
        <div class="text-h2 q-pa-md">
          {{ $t("upgrade-dialog.title") }}
        </div>
        <div class="q-pa-sm">
          {{ $t("upgrade-dialog.caption") }}
          <a :href="$t('links.pricing.url')" target="_blank">
            {{ $t("links.pricing.text") }}
          </a>
        </div>
        <div class="q-pa-sm">
          {{ $t("upgrade-dialog.subscribe.pre") }}
          <a
            :href="`mailto:${$t('upgrade-dialog.subscribe.mail')}?subject=${encodeURIComponent($t('upgrade-dialog.mail.subject'))}&body=${encodeURIComponent($t('upgrade-dialog.mail.template', { url: props.url }))}`"
          >
            {{ $t("upgrade-dialog.subscribe.mail") }}
          </a>
          {{ $t("upgrade-dialog.subscribe.post") }}
        </div>
        <div style="display: flex; flex-direction: row">
          <q-input
            dense
            outlined
            readonly
            :model-value="props.url"
            :autofocus="false"
            style="flex-grow: 1"
          />
          <ClipboardIcon :value="props.url" />
        </div>
      </q-card-section>
      <q-card-actions align="right">
        <q-btn
          :label="$t('upgrade-dialog.ok-button')"
          color="primary"
          @click="onDialogOK()"
          class="ok-button"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script setup lang="ts">
import { QInput, useDialogPluginComponent } from "quasar";
import ClipboardIcon from "./components/ClipboardIcon.vue";

defineEmits({ ...useDialogPluginComponent.emitsObject });
const props = defineProps<{
  url: string;
}>();

const { dialogRef, onDialogHide, onDialogOK } = useDialogPluginComponent();
</script>
