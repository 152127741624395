<template>
  <q-tooltip :max-width="props.maxWidth">
    <slot />
  </q-tooltip>
</template>

<script setup lang="ts">
const props = withDefaults(defineProps<{ maxWidth?: string }>(), {
  maxWidth: "400px",
});
</script>
