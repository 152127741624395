<template>
  <q-btn flat dense round icon="mdi-content-copy" @click="copyToClipboard()">
    <Tooltip>{{ $t("clipboard-icon.tooltip.hint") }}</Tooltip>
    <q-tooltip
      :model-value="success !== undefined"
      @update:model-value="null"
      anchor="top middle"
      self="bottom middle"
      :offset="[10, 10]"
    >
      {{
        success
          ? $t("clipboard-icon.tooltip.success")
          : $t("clipboard-icon.tooltip.failed")
      }}
    </q-tooltip>
  </q-btn>
</template>

<script setup lang="ts">
import { ref } from "vue";

const props = withDefaults(
  defineProps<{
    value: string;
    timeout?: number;
  }>(),
  {
    timeout: () => 2000,
  },
);

const success = ref<boolean | undefined>(undefined);
function copyToClipboard() {
  navigator.clipboard
    .writeText(props.value)
    .then(() => {
      success.value = true;
      setTimeout(() => {
        success.value = undefined;
      }, props.timeout);
    })
    .catch(() => {
      success.value = false;
    });
}
</script>
