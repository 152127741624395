import { type InjectionKey, computed, type ComputedRef } from "vue";
import { useRouter, type Router, type RouteRecordName } from "vue-router";

export class Routing {
  public static InjectionKey = Symbol() as InjectionKey<Routing>;

  public readonly currentRouteName: ComputedRef<
    RouteRecordName | undefined | null
  >;
  public readonly projectName: ComputedRef<string | undefined>;
  public readonly isProjectRoute: ComputedRef<boolean>;

  constructor(private router: Router) {
    router.beforeEach((to) => {
      const project = to.params.project;
      if (to.meta.project && typeof project === "string")
        window.localStorage.setItem("last_project", project);
    });

    this.currentRouteName = computed(() => this.router.currentRoute.value.name);
    this.projectName = computed(() => {
      const projects = this.router.currentRoute.value.params?.project;
      if (projects === undefined) return undefined;
      if (typeof projects === "string") return projects;
      return projects[0];
    });
    this.isProjectRoute = computed(
      () => !!this.router.currentRoute.value.meta.project,
    );
  }

  get query() {
    return this.router.currentRoute.value.query;
  }

  get lastProject(): string | null {
    return window.localStorage.getItem("last_project");
  }

  set authenticationGuard(guard: () => boolean) {
    this.router.beforeEach((to) => {
      if (to.meta.admin && !guard()) return { name: "login" };
    });
  }

  back() {
    this.router.go(-1);
  }

  async unauthorized() {
    await this.router.push({ name: "login" });
  }

  async admin() {
    await this.router.push({ name: "project-redirect" });
  }

  async project(project: string) {
    await this.router.push({ name: "project", params: { project: project } });
  }

  sameOrDefaultRouteWithProject(project: string) {
    return {
      name: this.isProjectRoute
        ? (this.router.currentRoute.value.name as RouteRecordName)
        : "project",
      params: { project },
    };
  }

  async users(project: string | undefined = undefined) {
    await this.router.push({
      name: "users",
      params: { project: project || this.projectName.value },
    });
  }

  async settings(project: string | undefined = undefined) {
    await this.router.push({
      name: "settings",
      params: { project: project || this.projectName.value },
    });
  }

  async createProject() {
    await this.router.push({ name: "create-project" });
  }
}

export function createRouting(): Routing {
  const router = useRouter();
  return new Routing(router);
}
