<template>
  <q-icon name="mdi-help-circle-outline" class="q-px-xs">
    <q-tooltip max-width="400px" v-if="props.tooltip">{{
      props.tooltip
    }}</q-tooltip>
  </q-icon>
</template>

<script setup lang="ts">
const props = defineProps<{ tooltip?: string }>();
</script>
