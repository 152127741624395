<template>
  <GuestLayout
    :on-premises="!activationToken"
    :create-account="!activationToken"
  >
    <q-card
      v-if="state === 'expired'"
      style="padding: 20px"
      class="expired-view"
    >
      <q-card-section class="text-center q-gutter-md">
        <div class="text-h6">{{ $t("login.activation-expired.title") }}</div>
        <div>
          {{ $t("login.activation-expired.start-over") }}
          <router-link :to="{ name: 'create-account' }">
            {{ $t("login.activation-expired.create-account") }}
          </router-link>
        </div>
      </q-card-section>
    </q-card>
    <q-form v-else ref="formRef" greedy class="login-view">
      <q-card style="padding: 100px 150px">
        <q-card-section class="text-h4 text-center">
          {{ $t("login.title") }}
        </q-card-section>
        <q-card-section v-if="state === 'failed'" class="q-pa-sm">
          <q-banner rounded class="error">
            {{ $t("login.invalid-login") }}
          </q-banner>
        </q-card-section>
        <q-card-section class="q-gutter-lg q-pa-sm">
          <q-input
            outlined
            :label="$t('login.admin-email')"
            type="email"
            v-model="adminEmail"
            :autofocus="!adminEmail"
            :rules="[
              (val) => (val && val.length > 0) || $t('input.required'),
              (val, rules) => rules.email(val) || $t('input.enter-valid-email'),
            ]"
            lazy-rules
            class="admin-email"
            :readonly="!!activationToken"
            input-class="admin-email-input"
            @keyup.enter="
              $event.target.form.querySelector('.password-input').focus()
            "
          />
          <q-input
            outlined
            type="password"
            :label="$t('login.password')"
            v-model="password"
            :autofocus="!!adminEmail"
            @keyup.enter="login"
            :rules="[(val) => (val && val.length > 0) || $t('input.required')]"
            lazy-rules
            class="password"
            input-class="password-input"
          />
        </q-card-section>
        <q-card-actions align="right" class="q-gutter-md">
          <router-link :to="{ name: 'password-reset' }" id="forgot-password">
            {{ $t("login.forgot-password") }}
          </router-link>
          <q-btn
            :label="$t('login.login')"
            @click="login"
            :loading="state === 'loading'"
            class="login-button"
            color="primary"
          />
        </q-card-actions>
      </q-card>
    </q-form>
  </GuestLayout>
</template>

<script setup lang="ts">
import { decodedValidSub } from "~/jwt";
import { ref, type Ref, inject, onMounted, computed } from "vue";
import { Routing } from "~/routing";
import { ApiAuthentication } from "~/api";
import { QInput, useQuasar } from "quasar";
import GuestLayout from "~/GuestLayout.vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const quasar = useQuasar();
const routing = inject(Routing.InjectionKey) as Routing;
const auth = inject(ApiAuthentication.InjectionKey) as ApiAuthentication;

const formRef = ref();

const adminEmail = ref("");
const password = ref("");

const activationToken = computed(() =>
  routing?.query?.activation_token?.toString(),
);

const state: Ref<"ready" | "loading" | "failed" | "expired"> = ref("ready");

async function login() {
  state.value = "loading";
  try {
    const validForm = await formRef.value.validate();
    if (!validForm) return;
    if (activationToken.value) {
      const created = await auth.createAdmin(
        adminEmail.value,
        password.value,
        activationToken.value,
      );
      if (!created) {
        state.value = "expired";
        return;
      } else {
        quasar.notify({
          type: "positive",
          classes: "notify-account-activated",
          message: adminEmail.value,
          caption: t("login.account-activated"),
        });
      }
    }
    await auth.updateToken(adminEmail.value, password.value);
    await routing.admin();
  } catch {
    state.value = "failed";
  } finally {
    if (state.value === "loading") state.value = "ready";
  }
}

onMounted(() => auth.revokeToken());
onMounted(() => {
  if (!activationToken.value) return;
  const sub = decodedValidSub(activationToken.value);
  if (sub === undefined) {
    state.value = "expired";
  } else adminEmail.value = sub;
});
</script>
