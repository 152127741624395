import { jwtDecode } from "jwt-decode";

export function decodedValidSub(
  token: string,
  requireExpiry: boolean = true,
): string | undefined {
  try {
    const decoded = jwtDecode(token);
    if (decoded === undefined) return undefined;

    if (requireExpiry || decoded.exp) {
      const expires = parseFloat(decoded.exp as unknown as string);
      if (expires === undefined) return undefined;
      if (isNaN(expires)) return undefined;
      if (Date.now() / 1000 > expires) return undefined;
    }

    if (typeof decoded.sub !== "string") return undefined;
    return decoded.sub;
  } catch {
    return undefined;
  }
}
