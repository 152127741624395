<template>
  <BaseLayout>
    <template v-slot:toolbar>
      <div class="fit row justify-start items-center q-pr-lg">
        <div class="col-auto row justify-content-center">
          <img :src="$t('app.logo')" class="full-height app-logo" />
          <slot name="project-selection" />
        </div>
        <div class="col-grow" />
        <div class="col-auto">
          <div class="row items-center">
            <span id="admin-email" class="text-h5 text-grey">{{ admin }}</span>
            <q-btn
              :to="{ name: 'login' }"
              icon="mdi-logout"
              size="xs"
              flat
              dense
              id="logout"
            />
          </div>
        </div>
      </div>
    </template>

    <template v-slot:navbar v-if="$slots.navbar">
      <slot name="navbar" />
    </template>

    <template v-slot:default>
      <div class="full-height">
        <slot />
      </div>
    </template>
  </BaseLayout>
</template>

<style lang="scss">
.project-select .q-btn-dropdown--current {
  cursor: default;
}

.project-select > .q-hoverable:hover > .q-focus-helper {
  background: white !important;
}

.nav-item {
  color: grey;
}

.nav-item.q-router-link--active {
  color: $primary;
}

.app-logo {
  width: 56px;
  padding-right: 6px;
}
</style>

<script setup lang="ts">
import { inject } from "vue";
import { ApiAuthentication } from "~/api";
import BaseLayout from "~/BaseLayout.vue";

const auth = inject(ApiAuthentication.InjectionKey) as ApiAuthentication;
// navigation guard only allows route when auth.authenitcedAs is valid
const admin = auth.authenticatedAs as string;
</script>
