<template>
  <ContentList :loading="loading_settings">
    <q-item class="q-pa-none">
      <q-item-section class="text-h2">{{
        $t("navigation.settings")
      }}</q-item-section>
    </q-item>

    <q-item class="q-py-md q-px-none">
      <q-item-section>
        <q-item-label class="text-h5">{{
          $t("settings.key.label")
        }}</q-item-label>
        <q-item-label caption>
          {{ $t("settings.key.hint") }}
          <a :href="$t('links.documentation.config.url')" target="_blank">
            {{ $t("settings.key.hint-link-text") }}
          </a>
        </q-item-label>
      </q-item-section>
      <q-item-section avatar>
        <q-input
          outlined
          style="width: 370px"
          readonly
          :autofocus="false"
          :input-class="credentialKeyVisible ? 'text' : 'password'"
          :model-value="credentialKey"
          data-test="mobileid-key"
        />
      </q-item-section>
      <q-item-section side>
        <div>
          <q-btn
            flat
            dense
            round
            :icon="credentialKeyVisible ? 'mdi-eye-off' : 'mdi-eye'"
            @click="changecredentialKeyVisibility()"
            class="toggle-mobileid-key-visibility"
          >
            <Tooltip>{{ credentialKeyVisible ? "Hide" : "Show" }}</Tooltip>
          </q-btn>
          <ClipboardIcon
            :value="credentialKey"
            class="copy-mobileid-key-to-clipboard"
          />
        </div>
      </q-item-section>
    </q-item>

    <div v-if="!projectState.settings.value?.selfservice">
      <q-separator class="q-my-lg" size="2px" />

      <q-item class="q-py-md q-px-none">
        <q-item-section>
          <q-item-label class="text-h5">{{
            $t("settings.autosync.label")
          }}</q-item-label>
          <q-item-label caption>
            {{ $t("settings.autosync.setup.text") }}
            <a :href="$t('links.documentation.autosync.url')" target="_blank">
              {{ $t("settings.autosync.setup.link.text") }}
            </a>
          </q-item-label>
        </q-item-section>
      </q-item>
      <q-item class="q-px-none q-py-none">
        <q-item-section>
          <q-item-label class="text-bold">
            {{ $t("settings.autosync.enable.label") }}
            <HelpIcon :tooltip="$t('settings.autosync.enable.tooltip')" />
          </q-item-label>
        </q-item-section>
        <q-item-section avatar>
          <q-toggle
            class="autosync-control"
            :model-value="autosyncStatus.enabled"
            @update:model-value="(value) => updateAutosyncStatus(!!value)"
          >
          </q-toggle>
        </q-item-section>
      </q-item>
      <q-item-label
        v-if="autosyncStatus.enabled"
        class="autosync-section q-pa-none"
      >
        <q-btn
          :label="$t('settings.autosync.task.download.text')"
          color="primary"
          @click="downloadAutoSyncTaskConfig"
          class="config-task-download-button"
        />

        <div class="autosync-status" v-if="autosyncStatus.last_autosync">
          <div class="info">
            {{
              $t("settings.autosync.status", {
                date: autosyncStatus.last_autosync?.toISOString(),
              })
            }}
          </div>
          <div class="text-grey q-pl-sm q-pt-sm q-pb-md">
            {{ $t("settings.autosync.stop.text.pre") }}
            <a
              :href="$t('links.documentation.autosync.stop.url')"
              target="_blank"
            >
              {{ $t("settings.autosync.stop.text.anchor") }}
            </a>
            {{ $t("settings.autosync.stop.text.post") }}
          </div>
        </div>

        <div class="warning" style="margin-bottom: 40px">
          {{ $t("settings.autosync.warning") }}
        </div>
      </q-item-label>
    </div>

    <div v-if="!!projectState.settings.value?.selfservice">
      <q-separator class="q-my-lg" size="2px" />

      <q-item class="q-py-md q-px-none">
        <q-item-section>
          <q-item-label class="text-h5">
            {{ $t("settings.selfservice.label") }}
          </q-item-label>
        </q-item-section>
      </q-item>
      <q-item class="q-pa-none">
        <div class="col">
          <div class="text-bold">
            {{ $t("settings.selfservice.qr-code.title") }}
          </div>
          <div class="text-caption text-grey">
            {{ $t("settings.selfservice.qr-code.hint") }}
            <a :href="$t('links.selfservice.url')" target="_blank">{{
              $t("settings.selfservice.qr-code.learn")
            }}</a>
          </div>
          <QRCode
            ref="qrcode"
            :value="projectState.settings.value?.selfservice.url || ''"
            :size="128"
            :border="0"
            class="q-py-md"
          />
          <q-btn
            @click="qrcode.download(projectState.name)"
            style="width: 128px"
            class="download-selfservice-qrcode"
          >
            {{ $t("settings.selfservice.download") }}
          </q-btn>
          <div class="text-bold q-pt-md">
            {{ $t("settings.selfservice.registered-ids.title") }}
          </div>
          <div class="text-grey">
            {{
              projectState.settings.value?.selfservice?.usedCredentials
                ? projectState.settings.value?.selfservice
                    ?.usedCredentials?.[0] ==
                  projectState.settings.value?.selfservice?.usedCredentials?.[1]
                  ? $t("settings.selfservice.registered-ids.single-id", {
                      id: projectState.settings.value?.selfservice
                        ?.usedCredentials?.[0],
                    })
                  : $t("settings.selfservice.registered-ids.multiple-ids", {
                      start:
                        projectState.settings.value?.selfservice
                          ?.usedCredentials?.[0],
                      end: projectState.settings.value?.selfservice
                        ?.usedCredentials?.[1],
                    })
                : $t("settings.selfservice.registered-ids.no-ids")
            }}
          </div>
        </div>
      </q-item>
    </div>
    <q-separator class="q-my-lg" size="2px" />

    <q-item class="q-py-md q-px-none">
      <q-item-section>
        <q-item-label class="text-h5">{{
          $t("settings.support.label")
        }}</q-item-label>
        <q-item-label class="text-bold">{{
          $t("settings.support.toggle.label")
        }}</q-item-label>
        <q-item-label caption class="line-wrap">
          {{ $t("settings.support.hint.text") }}
          <a :href="$t('links.statistics-policy.url')" target="_blank">
            {{ $t("settings.support.hint.learn-more.label") }}
          </a>
        </q-item-label>
      </q-item-section>
      <q-item-section avatar>
        <q-toggle
          :model-value="autoForwardSupportRequests"
          data-test="auto-forward-support-requests"
          @click="
            projectState.updateSettings({
              autoForwardSupportRequests: !autoForwardSupportRequests,
            })
          "
        >
        </q-toggle>
      </q-item-section>
    </q-item>

    <q-separator
      class="q-my-lg"
      size="2px"
      v-if="projectState.settings.value?.licenseStatus.canUpgrade"
    />

    <q-item
      v-if="projectState.settings.value?.licenseStatus.canUpgrade"
      class="license-upgrade q-py-md q-px-none"
    >
      <q-item-section>
        <q-item-label class="text-h5">{{
          $t("settings.upgrade.label")
        }}</q-item-label>
        <q-item-label caption>
          <a
            href="#"
            @click="
              quasar.dialog({
                component: UpgradeDialog,
                componentProps: {
                  url: projectState.settings.value?.licenseStatus.projectUrl,
                },
              })
            "
          >
            {{ $t("upgrade-dialog.label") }}
          </a>
          {{ $t("settings.upgrade.hint") }}
        </q-item-label>
      </q-item-section>
    </q-item>

    <q-separator class="q-my-lg" size="2px" />

    <q-item class="delete-project-section q-py-md q-px-none">
      <q-item-section>
        <q-item-label class="text-h5">{{
          $t("settings.delete.label")
        }}</q-item-label>
        <q-item-label>
          <a id="delete-project" href="#" @click="deleteProject()">
            {{ $t("settings.delete.pre") }}</a
          >
          {{ $t("settings.delete.post") }}
        </q-item-label>
      </q-item-section>
    </q-item>
  </ContentList>
</template>

<style>
.config-task-download-button {
  margin: 0 0 16px 0;
}

/*
Workaround to prevent browsers from storing the encryption key as password.
-webkit-text-security is supported by all major browsers
https://developer.mozilla.org/en-US/docs/Web/CSS/-webkit-text-security
*/
input.password {
  -webkit-text-security: disc;
  -webkit-text-stroke-width: 5px;
  letter-spacing: 5px;
}
</style>

<script setup lang="ts">
import { ref, inject, computed } from "vue";
import { ReactiveProjectState } from "~/ReactiveProjectState";
import { QInput } from "quasar";
import ClipboardIcon from "./components/ClipboardIcon.vue";
import { useQuasar, exportFile } from "quasar";
import UpgradeDialog from "~/UpgradeDialog.vue";
import QRCode from "~/components/QRCode.vue";
import { useI18n } from "vue-i18n";
import { useRetryDialog } from "~/retryDialog";
import { Routing } from "~/routing";
import ProjectDeletionDialog from "~/ProjectDeletionDialog.vue";

const quasar = useQuasar();
const { t } = useI18n();
const { withRetryDialog } = useRetryDialog();
const projectState = inject(
  ReactiveProjectState.InjectionKey,
) as ReactiveProjectState;
const routing = inject(Routing.InjectionKey) as Routing;

const loading_settings = computed(
  () => projectState.settings.value === undefined,
);
const projectSettings = computed(
  () =>
    projectState.settings.value || {
      credentialKey: "",
      autoForwardSupportRequests: false,
    },
);
const autoForwardSupportRequests = computed(
  () => projectSettings.value.autoForwardSupportRequests,
);
const autosyncStatus = computed(() => ({
  enabled: projectState.settings.value?.userManagementMode == "autosync",
  last_autosync: projectState.settings.value?.lastAutoSync,
}));

const credentialKeyVisible = ref(false);

const credentialKey = computed(() => {
  return [...projectSettings.value.credentialKey]
    .map((e) => e.charCodeAt(0).toString(16).padStart(2, "0").toUpperCase())
    .join(" ");
});

const qrcode = ref();

function changecredentialKeyVisibility(value: boolean | undefined = undefined) {
  credentialKeyVisible.value =
    value === undefined ? !credentialKeyVisible.value : value;
  if (credentialKeyVisible.value)
    setTimeout(() => changecredentialKeyVisibility(false), 5000);
}

async function updateAutosyncStatus(enabled: boolean) {
  if (!enabled) {
    quasar
      .dialog({
        title: t(`settings.autosync.disable.title`),
        message: t(`settings.autosync.disable.message`),
        class: "disable-autosync-dialog",
        ok: {
          label: t(`settings.autosync.disable.ok`),
          class: "ok-button",
        },
        cancel: {
          label: t(`settings.autosync.disable.cancel`),
          class: "cancel-button",
          flat: true,
        },
      })
      .onOk(() => {
        withRetryDialog(() => projectState.updateSettings({ autosync: false }));
      });
  } else await projectState.updateSettings({ autosync: true });
}

async function downloadAutoSyncTaskConfig() {
  quasar
    .dialog({
      title: t(`settings.autosync.task.download.dialog.title`),
      message: t(`settings.autosync.task.download.dialog.message`),
      class: "download-task-config-dialog",
      ok: {
        label: t(`settings.autosync.task.download.dialog.ok`),
        class: "ok-button",
      },
      cancel: {
        label: t(`settings.autosync.task.download.dialog.cancel`),
        class: "cancel-button",
        flat: true,
      },
    })
    .onOk(() => {
      projectState.autosyncConfig("task").then((file) => {
        exportFile(file.name, file);
      });
    });
}

async function deleteProject() {
  quasar
    .dialog({
      component: ProjectDeletionDialog,
      componentProps: {
        projectName: projectState.name,
      },
    })
    .onOk(() =>
      withRetryDialog(async () => {
        await projectState.delete();
        await routing.admin();
      }),
    );
}
</script>
