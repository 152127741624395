<template>
  <ContentList>
    <q-item class="q-pa-none">
      <q-item-section>
        <q-item-label class="text-h2">
          {{ $t("navigation.admins") }}
        </q-item-label>
        <q-item class="q-px-none q-py-md">
          <div class="text-xs">{{ $t("admins.hint") }}</div>
        </q-item>
        <q-item class="q-pa-none">
          <q-list class="admins full-width">
            <q-item
              v-for="admin in admins"
              :key="admin.email"
              class="q-pa-none admin"
            >
              <q-item-section>
                <q-item-label class="row items-center">
                  <a :href="`mailto:${admin.email}`">{{ admin.email }}</a>
                  <div v-if="admin.official" class="text-grey official">
                    &nbsp;{{ $t("admins.official-label") }}
                    <HelpIcon :tooltip="$t('admins.tooltips.official')" />
                  </div>
                </q-item-label>
              </q-item-section>
              <q-item-section>
                <q-item class="row items-center">
                  <q-btn
                    class="remove-admin q-pa-none"
                    @click="removeAdmin(admin.email)"
                    icon="mdi-close"
                    flat
                    dense
                    rounded
                    :color="admin.self ? 'grey' : 'negative'"
                    :disable="admin.self"
                  >
                    <Tooltip>
                      {{
                        admin.self
                          ? $t("admins.tooltips.remove-self")
                          : $t("admins.tooltips.remove")
                      }}
                    </Tooltip>
                  </q-btn>
                </q-item>
              </q-item-section>
            </q-item>
            <q-item class="q-pa-none q-pt-md">
              <q-item-section>
                <q-item-label>
                  <q-input
                    ref="newAdminRef"
                    outlined
                    type="email"
                    :label="$t('login.admin-email')"
                    v-model="adminEmail"
                    :rules="[
                      (val) => (val && val.length > 0) || $t('input.required'),
                      (val, rules) =>
                        rules.email(val) || $t('input.enter-valid-email'),
                      (val) =>
                        !admins
                          .map((a) => a.email.toLowerCase())
                          .includes(val.toLowerCase()) ||
                        $t('admins.error.exists'),
                    ]"
                    lazy-rules="ondemand"
                    class="new-admin"
                    hide-bottom-space
                  />
                </q-item-label>
              </q-item-section>
              <q-item-section style="justify-content: start">
                <q-item class="row items-center">
                  <q-btn class="add-admin" @click="addAdmin" color="primary">
                    {{ $t("admins.add.button.title") }}
                  </q-btn>
                </q-item>
              </q-item-section>
            </q-item>
          </q-list>
        </q-item>
      </q-item-section>
    </q-item>
  </ContentList>
</template>

<script setup lang="ts">
import { ref, inject, computed } from "vue";
import { ReactiveProjectState } from "~/ReactiveProjectState";
import { QInput } from "quasar";
import { useRetryDialog } from "~/retryDialog";
import { useQuasar } from "quasar";
import { useI18n } from "vue-i18n";

const projectState = inject(
  ReactiveProjectState.InjectionKey,
) as ReactiveProjectState;
const { withRetryDialog } = useRetryDialog();
const quasar = useQuasar();
const { t } = useI18n();

const admins = computed(() =>
  (projectState.admins.value || []).toSorted(
    (
      a: { readonly email: string; readonly official: boolean },
      b: { readonly email: string; readonly official: boolean },
    ) => {
      if (a.official && !b.official) return -1;
      if (b.official && !a.official) return 1;
      if (a.email < b.email) return -1;
      return 1;
    },
  ),
);
const adminEmail = ref("");
const newAdminRef = ref();

async function addAdmin() {
  if (!newAdminRef.value.validate()) return;
  await withRetryDialog(() => projectState.addAdmin(adminEmail.value));
  quasar.notify({
    type: "positive",
    classes: "notify-add-admin-success",
    message: `${adminEmail.value}`,
    caption: t("admins.add.notify.message"),
  });
  adminEmail.value = "";
}

function removeAdmin(adminEmail: string) {
  quasar
    .dialog({
      title: t(`admins.remove.dialog.title`, {
        email: adminEmail,
      }),
      class: "remove-admin-dialog",
      ok: {
        label: t(`admins.remove.dialog.ok-button`),
        class: "ok-button",
      },
      cancel: {
        label: t(`admins.remove.dialog.cancel-button`),
        class: "cancel-button",
        flat: true,
      },
    })
    .onOk(() => {
      withRetryDialog(() => projectState.removeAdmin(adminEmail));
      quasar.notify({
        type: "positive",
        classes: "notify-remove-admin-success",
        message: `${adminEmail}`,
        caption: t("admins.remove.notify.message"),
      });
    });
}
</script>
