<template>
  <q-dialog
    :model-value="true"
    ref="dialogRef"
    class="retry-dialog"
    :persistent="true"
  >
    <q-card class="q-px-lg q-pt-lg">
      <q-card-section>
        <div class="text-h5 text-grey">{{ t("retry-dialog.caption") }}</div>
        <div class="text-h4">{{ props.failureInfo }}</div>
        <div>
          {{ $t("retry-dialog.message") }}
        </div>
        <div>
          <div class="text-bold">{{ $t("retry-dialog.report") }}</div>
          <div class="contact-info">
            <a :href="`mailto:${$t('retry-dialog.mail')}`">{{
              $t("retry-dialog.mail")
            }}</a>
            <br />
            <a :href="`tel:${t('retry-dialog.phone')}`">
              {{ $t("retry-dialog.phone") }}
            </a>
          </div>
        </div>
        <q-card-actions align="right">
          <q-btn label="Retry" color="primary" @click="onDialogOK()" />
        </q-card-actions>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { useDialogPluginComponent } from "quasar";

const { t } = useI18n();

defineEmits({ ...useDialogPluginComponent.emitsObject });
const props = defineProps<{
  failureInfo: string;
}>();

const { dialogRef, onDialogOK } = useDialogPluginComponent();
</script>
