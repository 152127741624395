<template>
  <BaseLayout>
    <div
      class="fixed-center-top"
      style="min-width: 600px; max-width: 1000px; width: 40%"
    >
      <div class="row justify-center q-pa-md top">
        <img :src="$t('app.logo')" style="max-height: 40px" class="q-px-md" />
        <div class="text-h3">{{ $t("app.title") }}</div>
      </div>
      <slot />
      <div class="bottom">
        <div class="row column items-center q-pa-md" v-if="props.createAccount">
          <div class="text-bold">{{ $t("guest.new") }}</div>
          <div>
            <router-link :to="{ name: 'create-account' }" id="create-account">
              {{ $t("guest.create-account") }}
            </router-link>
          </div>
        </div>
        <div class="row column items-center q-pa-md" v-if="props.loginRedirect">
          <div class="text-bold">{{ $t("guest.existing-account") }}</div>
          <div>
            <router-link :to="{ name: 'login' }">
              {{ $t("guest.login") }}
            </router-link>
          </div>
        </div>
        <div class="row column items-center q-pa-md" v-if="props.onPremises">
          <div class="text-bold">{{ $t("guest.on-premises") }}</div>
          <div>
            <a target="_blank" :href="$t('links.on-premises.url')">
              {{ $t("guest.setup-yourself") }}</a
            >
          </div>
        </div>
      </div>
    </div>
  </BaseLayout>
</template>

<style>
.top {
  position: absolute;
  bottom: 100%;
  right: 50%;
  transform: translate(50%, 0%);
}

.bottom {
  position: absolute;
  top: 100%;
  right: 50%;
  transform: translate(50%, 0%);
}
</style>

<script setup lang="ts">
import BaseLayout from "~/BaseLayout.vue";

export interface Props {
  onPremises?: boolean;
  loginRedirect?: boolean;
  createAccount?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  onPremises: false,
  loginRedirect: false,
  createAccount: false,
});
</script>
