<template>
  <AdminLayout>
    <q-form
      ref="formRef"
      greedy
      class="fixed-center-top"
      style="min-width: 500px; max-width: 800px; width: 40%"
    >
      <q-card class="q-pa-xl">
        <q-card-section class="text-h4 text-center">
          {{ $t("create-project.title") }}
        </q-card-section>
        <q-card-section>
          <q-input
            outlined
            :label="$t('create-project.project-name.title')"
            v-model="projectName"
            autofocus
            :rules="[
              (val) => (val && val.length > 0) || $t('input.required'),
              (val) =>
                val.length <= 60 ||
                $t('input.character-limit', { value: '60' }),
              (val) =>
                !invalidProjectNames.includes(val) ||
                $t('create-project.error'),
            ]"
            class="project-name"
          >
            <template v-slot:after>
              <q-icon size="xs" name="mdi-help-circle-outline">
                <Tooltip>
                  {{ $t("create-project.project-name.tooltip") }}
                </Tooltip>
              </q-icon>
            </template>
          </q-input>
        </q-card-section>
        <q-card-section class="q-pr-none">
          <div class="row items-center no-wrap">
            <div class="col">
              {{ $t("create-project.selfservice.text") }}
            </div>
            <div class="col-auto">
              <q-toggle
                v-model="selfsreviceRegistration"
                class="selfservice-registration"
              />
            </div>
          </div>

          <div class="text-grey">
            {{ $t("create-project.selfservice.hint") }}
            <a
              :href="$t('links.selfservice.url')"
              target="_blank"
              style="white-space: nowrap"
            >
              {{ $t("create-project.selfservice.learn") }}
            </a>
          </div>
        </q-card-section>
        <q-card-section>
          <div class="warning text-center">
            {{ $t("create-project.warning") }}
          </div>
        </q-card-section>
        <q-card-actions align="right">
          <q-btn
            :label="$t('create-project.cancel-button.title')"
            class="cancel-button"
            flat
            color="primary"
            @click="routing.admin()"
          />
          <q-btn
            :label="$t('create-project.create-button.title')"
            @click="createProject"
            class="create-button"
            color="primary"
            :loading="inProgress"
          />
        </q-card-actions>
      </q-card>
    </q-form>
  </AdminLayout>
</template>

<script setup lang="ts">
import { QInput } from "quasar";
import { ref, inject, reactive } from "vue";
import { ApiAuthentication } from "~/api";
import { Routing } from "~/routing";
import { useRetryDialog } from "~/retryDialog";
import AdminLayout from "~/AdminLayout.vue";

const api = inject(ApiAuthentication.InjectionKey) as ApiAuthentication;
const routing = inject(Routing.InjectionKey) as Routing;

const { withRetryDialog } = useRetryDialog();

const invalidProjectNames = reactive<string[]>([]);
const inProgress = ref(false);
const formRef = ref();
const projectName = ref("");
const forwardSupportRequests = ref(true);
const selfsreviceRegistration = ref(false);

async function createProject() {
  const success = await formRef.value.validate();
  if (success) {
    inProgress.value = true;
    try {
      const created = await withRetryDialog(() =>
        api.createProject({
          projectName: projectName.value,
          autoForwardSupportRequest: forwardSupportRequests.value,
          selfservice: selfsreviceRegistration.value,
        }),
      );
      if (created) await routing.project(projectName.value);
      else {
        invalidProjectNames.push(projectName.value);
        formRef.value.validate();
      }
    } finally {
      inProgress.value = false;
    }
  }
}
</script>
