import { createApp } from "vue";
import { createRouter, createWebHistory } from "vue-router";
import { Quasar, Dialog, Notify } from "quasar";
import { createI18n } from "vue-i18n";
import * as Sentry from "@sentry/vue";

import "quasar/src/css/index.sass";
import "@quasar/extras/material-icons/material-icons.css";
import "@quasar/extras/mdi-v7/mdi-v7.css";

import en from "./locales/en.yaml";
import en_baltech from "./locales/en-baltech.yaml";

import HelpIcon from "~/components/HelpIcon.vue";
import Tooltip from "~/components/Tooltip.vue";
import ContentList from "~/components/ContentList.vue";

import App from "~/App.vue";
import Users from "~/Users.vue";
import Settings from "~/Settings.vue";
import ProjectAdmins from "~/ProjectAdmins.vue";
import Login from "~/Login.vue";
import CreateProject from "~/CreateProject.vue";
import CreateAccount from "~/CreateAccount.vue";
import Project from "~/Project.vue";
import PasswordReset from "~/PasswordReset.vue";
import ProjectRedirect from "~/ProjectRedirect.vue";

import "~/main.scss";

const app = createApp(App);
const router = createRouter({
  routes: [
    {
      path: "/",
      redirect: { name: "project-redirect" },
    },
    {
      name: "login",
      path: "/login",
      component: Login,
    },
    {
      name: "password-reset",
      path: "/password-reset",
      component: PasswordReset,
    },
    {
      name: "create-account",
      path: "/create/account",
      component: CreateAccount,
    },
    {
      name: "create-project",
      path: "/create/project",
      component: CreateProject,
      meta: {
        admin: true,
      },
    },
    {
      name: "project-redirect",
      component: ProjectRedirect,
      path: "/projects",
      meta: {
        admin: true,
      },
    },
    {
      name: "project",
      component: Project,
      props: (route) => ({
        key: route.params.project,
      }),
      path: "/projects/:project",
      meta: {
        admin: true,
        project: true,
      },
      children: [
        {
          name: "users",
          path: "users",
          component: Users,
        },
        {
          name: "settings",
          path: "settings",
          component: Settings,
        },
        {
          name: "project-admins",
          path: "admins",
          component: ProjectAdmins,
        },
      ],
    },
    {
      path: "/:path(.*)",
      redirect: { name: "project-redirect", params: {} },
    },
  ],
  history: createWebHistory(),
});

const { sentry_dsn, full_version, environment, tracing, baltech_deployment } =
  window.__APP_CONFIG__;
if (sentry_dsn) {
  Sentry.init({
    app,
    dsn: sentry_dsn,
    release: full_version,
    environment: environment,
    enableTracing: tracing,
    trackComponents: tracing,
    integrations: tracing
      ? [
          Sentry.replayIntegration(),
          Sentry.browserProfilingIntegration(),
          Sentry.browserTracingIntegration({ router }),
        ]
      : [],
    tracesSampleRate: 1.0,
    profilesSampleRate: 1.0,
    replaysSessionSampleRate: 1.0,
  });
}

app
  .use(Quasar, {
    plugins: { Dialog, Notify },
    config: { notify: { position: "top" } },
  })
  .use(
    createI18n({
      locale: baltech_deployment ? "en-baltech" : "en",
      messages: { "en-baltech": en_baltech, en },
      missingWarn: false,
      fallbackWarn: false,
    }),
  )
  .use(router)
  .component("HelpIcon", HelpIcon)
  .component("Tooltip", Tooltip)
  .component("ContentList", ContentList)
  .mount("#app");
