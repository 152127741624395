<template>
  <q-table
    class="users-table full-width"
    :rows="users"
    :columns="columns"
    :loading="loading"
    :pagination="{ rowsPerPage: 0 }"
    color="blue"
    row-key="uuid"
    binary-state-sort
    :hide-bottom="!empty"
    :hide-no-data="loading"
  >
    <template v-slot:top v-if="users.length > 0">
      <div class="row full-width">
        <div class="col flex-column">
          <div class="text-h2">{{ $t("navigation.users") }}</div>
          <div class="text-xs q-mt-sm">
            {{ $t("users.hint") }}
          </div>
        </div>
        <div
          class="flex-column q-gutter-sm items-end q-mt-md q-mr-md"
          align="right"
          v-if="!empty"
        >
          <q-btn
            :label="$t('users.import')"
            color="primary"
            class="import-users-button"
            @click="showImportDialog()"
          />
          <q-btn
            :label="$t('users.add')"
            color="primary"
            class="add-user-button"
            @click="showAddUserDialog()"
          />
          <div v-if="licenseStatus?.canUpgrade">
            {{
              t(
                "users.user-limit",
                !licenseStatus?.valid
                  ? 0
                  : licenseStatus?.remainingFeatures.userCount || 0,
              )
            }}
            <a href="#" @click="showUpgradeDialog()" class="upgrade-button">{{
              t("upgrade-dialog.label")
            }}</a>
          </div>
        </div>
      </div>
    </template>

    <template v-slot:no-data>
      <div class="get-started">
        <div class="text-h3 stretch">
          {{ $t("users.get-started.title") }}
        </div>
        <q-img src="/reader.svg" fit="contain" />
        <div>
          <div class="text-h4">
            {{ $t("users.get-started.setup.title") }}
          </div>
          <div>
            {{ $t("users.get-started.setup.text") }}
          </div>
        </div>
        <div class="buttons">
          <q-btn
            color="primary"
            :href="t('links.documentation.config.url')"
            target="_blank"
          >
            {{ $t("users.get-started.setup.learn-more.text") }}
          </q-btn>
        </div>
        <q-img src="/user-and-app.svg" fit="contain" />
        <div>
          <div class="text-h4">
            {{ $t("users.get-started.invite.title") }}
          </div>
          <div>
            {{ $t("users.get-started.invite.text") }}
          </div>
        </div>
        <div class="buttons">
          <q-btn
            :label="$t('users.import')"
            color="primary"
            class="import-users-button"
            @click="showImportDialog()"
          />
          <q-btn
            :label="$t('users.add')"
            color="primary"
            class="add-user-button"
            @click="showAddUserDialog()"
          />
        </div>
        <div class="text-bold stretch">
          {{ $t("users.get-started.stuck.text") }}&nbsp;
          <a :href="t('links.support.url')" target="_blank">
            {{ $t("users.get-started.stuck.get-help") }}
          </a>
        </div>
      </div>
    </template>

    <template v-slot:header="props">
      <q-tr :props="props" v-if="!empty">
        <q-th v-for="col in props.cols" :key="col.name" :props="props">
          <span class="text-bold">{{ col.label }}</span>
          <HelpIcon v-if="col.tooltip" :tooltip="col.tooltip" />
        </q-th>
      </q-tr>
    </template>

    <template v-slot:body="props">
      <q-tr v-flash="props">
        <q-td>
          <CroppedText :text="props.row.firstName" class="text-column" />
        </q-td>

        <q-td>
          <CroppedText :text="props.row.lastName" class="text-column" />
        </q-td>

        <q-td>
          <a :href="'mailto:' + props.row.email">
            <CroppedText :text="props.row.email" class="text-column" />
          </a>
        </q-td>

        <q-td class="text-caption text-black">
          <CroppedText :text="props.row.credentialId" class="text-column" />
        </q-td>

        <q-td auto-width>
          <q-badge
            class="q-pa-xs"
            :color="getStatusColor(props.row.status)"
            :label="$t(`user.states.${props.row.status}.label`)"
          >
            <Tooltip>{{ getStatusTooltip(props.row.status) }}</Tooltip>
          </q-badge>
        </q-td>

        <q-td auto-width>
          <q-btn-dropdown
            dropdown-icon="mdi-dots-vertical"
            color="grey"
            flat
            dense
            menu-anchor="top right"
            menu-self="top left"
            :class="`user-menu-${props.row.uuid}`"
            v-if="
              props.row.status === 'active' ||
              props.row.status === 'activation_pending' ||
              props.row.status == 'app_reset'
            "
          >
            <q-list>
              <q-item
                v-if="props.row.email"
                class="q-pa-none"
                style="min-height: 0"
                clickable
                v-close-popup
                @click="
                  props.row.status == 'active'
                    ? inviteUserAfterConfirmation(props.row)
                    : inviteUser(props.row)
                "
                :class="`invite-user-${props.row.uuid}`"
              >
                <q-chip icon="mdi-email" color="transparent">
                  {{ $t("users.actions.resend-invitation.label") }}
                </q-chip>
                <Tooltip v-if="props.row.status === 'active'">
                  {{ $t("users.actions.resend-invitation.tooltip") }}
                </Tooltip>
              </q-item>

              <q-item
                class="q-pa-none"
                style="min-height: 0"
                clickable
                v-close-popup
                @click="showEditUserDialog(props.row)"
                :class="`edit-user-${props.row.uuid}`"
              >
                <q-chip icon="mdi-pencil" color="transparent">
                  {{ $t("users.actions.edit.label") }}
                </q-chip>
              </q-item>

              <q-item
                class="q-pa-none"
                style="min-height: 0"
                clickable
                v-close-popup
                @click="deleteUser(props.row)"
                :class="`delete-user-${props.row.uuid}`"
              >
                <q-chip
                  icon="mdi-close"
                  text-color="negative"
                  color="transparent"
                >
                  {{ $t("users.actions.delete.label") }}
                </q-chip>

                <Tooltip v-if="props.row.status === 'active'">
                  {{ $t("users.table.header.delete.tooltip-active") }}
                </Tooltip>
                <Tooltip v-else-if="props.row.status === 'activation_pending'">
                  {{
                    $t("users.table.header.delete.tooltip-activation-pending")
                  }}
                </Tooltip>
              </q-item>
            </q-list>
          </q-btn-dropdown>
        </q-td>
      </q-tr>
    </template>
  </q-table>
</template>

<script setup lang="ts">
import { type User, type UserStatus } from "~/api";
import { ReactiveProjectState } from "~/ReactiveProjectState";
import { computed, inject, type DirectiveBinding, reactive } from "vue";
import { useQuasar } from "quasar";
import { useRetryDialog } from "~/retryDialog";
import { useI18n } from "vue-i18n";
import UserDialog from "./UserDialog.vue";
import ImportDialog from "./ImportDialog.vue";
import UpgradeDialog from "./UpgradeDialog.vue";
import CroppedText from "~/components/CroppedText.vue";

const { t } = useI18n();
const quasar = useQuasar();
const projectState = inject(
  ReactiveProjectState.InjectionKey,
) as ReactiveProjectState;
const { withRetryDialog } = useRetryDialog();

const left: "left" | undefined = "left";
const columns = [
  {
    name: "first_name",
    label: t("user.first-name"),
    align: left,
    field: (user: User) => user.firstName,
    sortable: true,
  },
  {
    name: "last_name",
    label: t("user.last-name"),
    align: left,
    field: (user: User) => user.lastName,
    sortable: true,
  },
  {
    name: "email",
    label: t("user.email"),
    align: left,
    sortable: true,
    field: (user: User) => user.email,
  },
  {
    name: "credential_id",
    label: t("user.credential_id"),
    align: left,
    field: (user: User) => user.credentialId,
    sortable: true,
    tooltip: t("user.credential_id-hint"),
  },
  {
    name: "status",
    label: t("user.status"),
    align: left,
    field: (user: User) => user.status,
    sortable: true,
    sort: (a: UserStatus, b: UserStatus): number => {
      const order: UserStatus[] = [
        "activation_pending",
        "active",
        "deletion_pending",
      ];
      return order.indexOf(a) - order.indexOf(b);
    },
  },
  {
    name: "actions",
    label: "",
    align: left,
    field: () => "",
  },
];

const users = computed(() => projectState.users.value || []);
const loading = computed(() => projectState.users.value === undefined);
const empty = computed(
  () =>
    projectState.users.value !== undefined &&
    projectState.users.value.length == 0,
);
const licenseStatus = computed(
  () => projectState.settings.value?.licenseStatus,
);

function getStatusColor(status: string): string {
  if (status === "active") return "positive";
  else if (status === "activation_pending") return "primary";
  else if (status === "deletion_pending") return "warning";
  else if (status === "app_reset") return "grey";
  else return "secondary";
}

function getStatusTooltip(status: string): string {
  if (status === "activation_pending")
    return t("user.states.activation_pending.tooltip");
  if (status === "active") return t("user.states.active.tooltip");
  if (status === "app_reset") return t("user.states.app_reset.tooltip");
  if (status === "deletion_pending")
    return t("user.states.deletion_pending.tooltip");
  return "";
}

const pendingFlashes: Record<string, User> = reactive({});
function highlightIfPending(el: HTMLElement, user: User) {
  if (
    pendingFlashes[user.uuid] &&
    JSON.stringify(pendingFlashes[user.uuid]) === JSON.stringify(user)
  ) {
    delete pendingFlashes[user.uuid];

    // scroll area is complete table including header but the header is fixed to the top
    // by setting the header height as scroll margin
    // we avoid that the row is hidden behind the header after scrolling.
    el.style.scrollMarginTop = `${document.querySelector("thead")?.clientHeight || 0}px`;
    el.scrollIntoView({ block: "nearest" });

    el.animate(
      {
        backgroundColor: [
          el.style.backgroundColor || "inherit",
          "#d0ecf5",
          el.style.backgroundColor || "inherit",
        ],
        opacity: "0.5",
      },
      { duration: 1000 },
    );
  }
}
const vFlash = {
  mounted: (el: HTMLElement, binding: DirectiveBinding) =>
    highlightIfPending(el, binding.value.row),
  updated: (el: HTMLElement, binding: DirectiveBinding) =>
    highlightIfPending(el, binding.value.row),
};

function inviteUserAfterConfirmation(user: User) {
  quasar
    .dialog({
      title: t(`users.invite-user-dialog.title`, {
        firstName: user.firstName,
        lastName: user.lastName,
      }),
      class: "confirm-invite-user-dialog",
      message: t(`users.invite-user-dialog.message`),
      ok: {
        label: t(`users.invite-user-dialog.ok-button`),
        class: "ok-button",
      },
      cancel: {
        label: t(`users.invite-user-dialog.cancel-button`),
        class: "cancel-button",
        flat: true,
      },
    })
    .onOk(() => inviteUser(user));
}

function inviteUser(user: User) {
  withRetryDialog(() =>
    projectState.sendInvitation(user.uuid).then(() =>
      quasar.notify({
        type: "positive",
        classes: "notify-resent-invite-success",
        message: `${user.firstName} ${user.lastName}`,
        caption:
          user.status === "active"
            ? t("users.invite-resent.success-message.active")
            : t("users.invite-resent.success-message.pending"),
      }),
    ),
  );
}

function deleteUser(user: User) {
  const dialog = user.status === "active" ? "revoke-dialog" : "delete-dialog";
  quasar
    .dialog({
      title: t(`users.${dialog}.title`, {
        firstName: user.firstName,
        lastName: user.lastName,
      }),
      class: "confirm-delete-user-dialog",
      message: t(`users.${dialog}.message`),
      ok: {
        label: t(`users.${dialog}.ok-button`),
        class: "ok-button",
      },
      cancel: {
        label: t(`users.${dialog}.cancel-button`),
        class: "cancel-button",
        flat: true,
      },
    })
    .onOk(() => {
      withRetryDialog(
        async () => {
          const maybe_user = await projectState.deleteUser(user.uuid);

          if (!maybe_user) {
            quasar.notify({
              type: "positive",
              classes: "notify-delete-user-success",
              message: `${user.firstName} ${user.lastName}`,
              caption: t("users.delete-dialog.success-message"),
            });
          } else {
            user.status = maybe_user.status;
            quasar.notify({
              type: "warning",
              textColor: "white",
              classes: "notify-revoke-permissions-pending",
              message: `${user.firstName} ${user.lastName}`,
              caption: t("users.revoke-dialog.success-message"),
            });
          }
        },
        t(`users.${dialog}.failed-message`, {
          firstName: user.firstName,
          lastName: user.lastName,
        }),
      );
    });
}

function showUpgradeDialog(): void {
  quasar.dialog({
    component: UpgradeDialog,
    componentProps: {
      url: licenseStatus.value?.projectUrl,
    },
  });
}

function showAddUserDialog(): void {
  if (
    licenseStatus.value &&
    (!licenseStatus.value.valid ||
      (licenseStatus.value?.remainingFeatures.userCount !== null &&
        licenseStatus.value?.remainingFeatures.userCount < 1))
  )
    showUpgradeDialog();
  else
    quasar
      .dialog({
        component: UserDialog,
        componentProps: {
          title: t("users.add-user-dialog.title"),
          okLabel: t("users.add-user-dialog.ok-button"),
          cancelLabel: t("users.add-user-dialog.cancel-button"),
          uniqueFields: _uniqueFields(),
        },
      })
      .onOk((userInfo) => {
        withRetryDialog(
          () =>
            projectState
              .addUser(
                userInfo.lastName,
                userInfo.firstName,
                userInfo.email,
                userInfo.credentialId,
              )
              .then((user: User) => {
                projectState.sendInvitation(user.uuid);
                pendingFlashes[user.uuid] = user;
                quasar.notify({
                  type: "positive",
                  classes: "notify-add-user",
                  message: `${user.firstName} ${user.lastName}`,
                  caption: t("users.add-user-dialog.success-message"),
                });
              })
              .catch((e) => {
                if (e.statusCode == 409)
                  quasar.notify({
                    type: "negative",
                    classes: "notify-add-user-conflict",
                    message: t("users.add-user-dialog.failed-message", {
                      firstName: userInfo.firstName,
                      lastName: userInfo.lastName,
                    }),
                  });
                else if (e.statusCode === 591) showUpgradeDialog();
                else throw e;
              }),
          t("users.add-user-dialog.failed-message", {
            firstName: userInfo.firstName,
            lastName: userInfo.lastName,
          }),
        );
      });
}

function showEditUserDialog(user: User): void {
  const triggersResend = (newEmail: string) =>
    user.status === "activation_pending" && newEmail !== user.email;
  const triggersAppUpdate = (newId: string) =>
    user.status === "active" && newId !== user.credentialId;

  quasar
    .dialog({
      component: UserDialog,
      componentProps: {
        title: t("users.edit-user-dialog.title"),
        okLabel: (newUser: { email: string; credentialId: string }) => {
          if (triggersResend(newUser.email))
            return t("users.edit-user-dialog.ok-button.resend");
          else if (triggersAppUpdate(newUser.credentialId))
            return t("users.edit-user-dialog.ok-button.update");
          return t("users.edit-user-dialog.ok-button.save");
        },
        cancelLabel: t("users.edit-user-dialog.cancel-button"),
        initialUserInfo: user,
        uniqueFields: _uniqueFields(user.uuid),
        infos: {
          email: (val: string) =>
            triggersResend(val) &&
            t("users.edit-user-dialog.hints.triggers-resend"),
          credentialId: (val: string) =>
            triggersAppUpdate(val) &&
            t("users.edit-user-dialog.hints.triggers-app-update"),
        },
      },
    })
    .onOk((userInfo) => {
      withRetryDialog(
        () =>
          projectState
            .updateUser(
              user.uuid,
              userInfo.lastName,
              userInfo.firstName,
              userInfo.email,
              userInfo.credentialId,
            )
            .then((updatedUser: User) => {
              let caption = "changed";
              if (triggersResend(updatedUser.email)) caption = "resent";
              else if (triggersAppUpdate(updatedUser.credentialId))
                caption = "updated";
              pendingFlashes[updatedUser.uuid] = updatedUser;

              quasar.notify({
                type: "positive",
                classes: "notify-edit-user",
                message: `${updatedUser.firstName} ${updatedUser.lastName}`,
                caption: t(`users.edit-user-dialog.success-message.${caption}`),
              });
            })
            .catch((e) => {
              if (e.statusCode == 409)
                quasar.notify({
                  type: "negative",
                  classes: "notify-edit-user-conflict",
                  message: t("users.edit-user-dialog.failed-message", {
                    firstName: userInfo.firstName,
                    lastName: userInfo.lastName,
                  }),
                });
              else throw e;
            }),
        t("users.edit-user-dialog.failed-message", {
          firstName: userInfo.firstName,
          lastName: userInfo.lastName,
        }),
      );
    });
}

function _uniqueFields(
  exclude_uuid: string | undefined = undefined,
): { email: string; credentialId: string }[] {
  return users.value
    .filter((u) => u.status !== "deletion_pending")
    .filter((u) => exclude_uuid === undefined || u.uuid != exclude_uuid)
    .map((u) => {
      return { email: u.email, credentialId: u.credentialId };
    });
}

function showImportDialog(): void {
  quasar.dialog({
    component: ImportDialog,
    componentProps: { projectState, onUpgrade: showUpgradeDialog },
  });
}
</script>

<style lang="sass">
.users-table
  max-height: 100%

  padding-bottom: 30px
  .q-table__top
    padding-top: 30px
  .q-table__top,
  .q-table__middle
    padding-inline: 30px

  .q-table__top,
  .q-table__bottom,
  thead tr:first-child th /* bg color is important for th; just specify one */
    background-color: #fff

  thead tr th
    position: sticky
    z-index: 1
  /* this will be the loading indicator */
  thead tr:last-child th
    /* height of all previous header rows */
    top: 48px
  thead tr:first-child th
    top: 0

.text-column
  max-width: calc(100vw / 8)

.get-started
  display: grid
  height: 100%
  grid-template-columns: minmax(auto, 120px) auto max-content
  max-width: 1200px
  margin: auto
  padding: 3em 3em calc(3em - 30px)
  row-gap: 3em
  column-gap: 2em

  .stretch
    grid-column: 1 / 4
    text-align: center

  .buttons
    margin: auto 0

    :not(:last-child)
      margin-right: 1em
</style>
