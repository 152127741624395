<template>
  <q-input
    outlined
    :type="visible ? 'text' : 'password'"
    :label="$t('password-validation.title')"
    v-model="password"
    @update:model-value="emit('update', isValid ? password : undefined)"
    class="password"
    input-class="password-input"
    bottom-slots
    hide-bottom-space
  >
    <template v-slot:append>
      <q-icon
        :name="visible ? 'mdi-eye' : 'mdi-eye-off'"
        class="cursor-pointer"
        @click="visible = !visible"
      />
    </template>
    <template v-slot:hint v-if="!isValid">
      <ul class="q-pl-lg q-mt-none" style="opacity: 0.7">
        <li
          v-for="[n, v] in validations"
          :key="n as string"
          :class="v ? 'ok' : 'missing'"
        >
          {{ n }}
        </li>
      </ul>
    </template>
  </q-input>
</template>

<style>
li.ok {
  list-style: "\2714\FE0F"; /* check mark emoji */
  /* workaround to set the color of the emoji */
  color: transparent;
  text-shadow: 0 0 0 darkgreen;
}

div.ok {
  color: darkgreen;
}
</style>

<script setup lang="ts">
import { ref, computed } from "vue";
import { QInput } from "quasar";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const emit = defineEmits<{
  update: [password: string | undefined];
}>();

const password = ref("");
const visible = ref(false);

const vt = (name: string) => t(`password-validation.rules.${name}`);

const validations = computed(() => [
  [vt("lowercase-char"), /[a-z]/.test(password.value)],
  [vt("uppercase-char"), /[A-Z]/.test(password.value)],
  [vt("digit"), /\d/.test(password.value)],
  [vt("special-char"), /\W|_/.test(password.value)],
  [vt("length"), password.value.length >= 8],
]);

const isValid = computed(() => {
  return validations.value.every(([, v]) => v);
});
</script>
